import TagManager from 'react-gtm-module'

interface MailchimpSignupProps {
	spaceTitle?: string
	spaceSubject?: string
	labelClass?: string
	submitButtonClass?: string
	containerClass?: string
}

const MailchimpSignup = ({
	spaceTitle,
	spaceSubject,
	labelClass = 'text-white text-left opacity-75 font-normal',
	submitButtonClass = 'button bg-yellow-300 text-gray-900 hover:bg-yellow-200',
	containerClass = 'w-full flex flex-col md:flex-row justify-center items-stretch flex-wrap',
}: MailchimpSignupProps) => {
	return (
		<div id="mc_embed_signup">
			<form
				action="https://masterchallenge.us19.list-manage.com/subscribe/post?u=db96a418538dd040888d30e45&amp;id=1c881bf564"
				method="post"
				id="mc-embedded-subscribe-form"
				name="mc-embedded-subscribe-form"
				className="validate block w-full"
				target="_blank"
			>
				<div id="mc_embed_signup_scroll" className={containerClass}>
					<div className="mc-field-group md:mr-4 mb-4 md:mb-6 w-full md:w-auto">
						<label className={labelClass} htmlFor="mce-FNAME">
							First Name{' '}
						</label>
						<input
							type="text"
							placeholder="Your name"
							defaultValue=""
							name="FNAME"
							className="w-full md:w-64 required"
							id="mce-FNAME"
							required
						/>
					</div>

					<div className="mc-field-group md:mr-4 mb-4 md:mb-6 w-full md:w-auto">
						<label className={labelClass} htmlFor="mce-EMAIL">
							Email Address{' '}
						</label>
						<input
							placeholder="Your email"
							type="email"
							defaultValue=""
							name="EMAIL"
							className="w-full md:w-64 required email  max-w-full"
							id="mce-EMAIL"
							required
						/>
					</div>

					<div className="mc-field-group md:mr-4 mb-4 md:mb-6 w-full md:w-auto relative">
						<div className="relative inline-block w-full md:w-auto">
							<label className={labelClass} htmlFor="mce-SEGMENT">
								About you
							</label>
							<select
								placeholder="I am a"
								defaultValue=""
								name="SEGMENT"
								className="w-full required appearance-none select md:w-64 max-w-full"
								id="mce-SEGMENT"
								required
							>
								<option value="">I am a..</option>
								<option value="company">company</option>
								<option value="lecturer">lecturer</option>
								<option value="student">student</option>
							</select>
							<div className="absolute bottom-3 right-0 flex items-center px-2 pointer-events-none">
								<svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
									<path
										d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
										clipRule="evenodd"
										fillRule="evenodd"
									></path>
								</svg>
							</div>
						</div>
					</div>

					<input
						type="hidden"
						defaultValue={spaceSubject}
						name="SPACESBJCT"
						id="mce-SPACESBJCT"
					/>
					<input
						type="hidden"
						defaultValue={spaceTitle}
						name="SPACETITLE"
						id="mce-SPACETITLE"
					/>

					<div id="mce-responses" className="clear">
						<div
							className="response"
							id="mce-error-response"
							style={{ display: 'none' }}
						></div>
						<div
							className="response"
							id="mce-success-response"
							style={{ display: 'none' }}
						></div>
					</div>

					<div
						style={{ position: 'absolute', left: '-5000px' }}
						aria-hidden="true"
					>
						<input
							type="text"
							name="b_db96a418538dd040888d30e45_1c881bf564"
							tabIndex={-1}
							defaultValue=""
						/>
					</div>

					<div className="clear w-auto flex flex-row justify-center items-end mb-4 md:mb-6">
						<button
							type="submit"
							value="Subscribe"
							name="subscribe"
							id="mc-embedded-subscribe"
							onClick={() => {
								const elements = [
									...(document.querySelectorAll(
										'#mc_embed_signup [required]'
									) as any),
								].map((each) => each.value)
								const inputValuesCheck = elements.every(
									(current) => current !== ''
								)

								if (!inputValuesCheck) return

								TagManager.dataLayer({
									dataLayer: {
										page: window.location.href,
										event: 'footerSubmit',
									},
								})
							}}
							className={
								'bg-yellow-300 text-gray-900 hover:bg-yellow-200' +
								submitButtonClass
							}
						>
							Send
						</button>
					</div>
				</div>
			</form>
		</div>
	)
}

export default MailchimpSignup
