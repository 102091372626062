import React from 'react'
import Link from 'next/link'
import CookieConsent from 'react-cookie-consent'
import {
	faInstagram,
	faLinkedinIn,
	faMediumM,
	faYoutube,
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MailchimpSignup from './MailchimpSignup'

const StudentsIllustration =
	require('../../../assets/svg/students.svg') as string

interface FooterProps {
	spaceTitle?: string
	spaceSubject?: string
	showForm?: boolean
}

const Footer = ({ spaceTitle, spaceSubject, showForm = true }: FooterProps) => (
	<footer className="relative pb-6 border-t leading-relaxed z-20">
		<div className="absolute z-10 h-0 w-1/6 ml-4 top-1 left-0 p-0 footer__illustration-wrapper">
			<StudentsIllustration />
		</div>

		{showForm && (
			<div className="container mx-auto px-4 pb-10">
				<div
					id="mc_embed_signup"
					className="shadow-lg -mt-10 relative z-20 px-6 py-8 text-center mx-auto bg-purple-900 rounded-lg"
				>
					<h4 className="text-2xl font-semibold text-white opacity-85 mb-6">
						Leave your email address to receive monthly challenge opportunities
					</h4>
					<MailchimpSignup
						spaceTitle={spaceTitle}
						spaceSubject={spaceSubject}
					/>
				</div>
			</div>
		)}

		<div className="container mx-auto p-4 mb-4">
			<div className="flex flex-col">
				<h5 className="text-gray-700 uppercase text-sm">Follow us</h5>
				<ul className="flex items-center flex-wrap">
					<li className="flex-grow -mr-px">
						<a
							className="w-full rounded-l h-12 hover:bg-gray-50 transition-colors duration-300 flex items-center justify-center border bg-white text-lg mt-4 text-gray-500 hover:text-purple-900"
							href="https://www.linkedin.com/company/masterchallenge"
							target="_blank"
							rel="noreferrer"
						>
							<FontAwesomeIcon icon={faLinkedinIn} />
						</a>
					</li>
					<li className="flex-grow -mr-px">
						<a
							className="w-full h-12 hover:bg-gray-50 transition-colors duration-300 flex items-center justify-center border bg-white text-lg mt-4 text-gray-500 hover:text-purple-900"
							href="https://www.instagram.com/masterchallenge_me/"
							target="_blank"
							rel="noreferrer"
						>
							<FontAwesomeIcon icon={faInstagram} />
						</a>
					</li>
					<li className="flex-grow -mr-px">
						<a
							className="w-full h-12 hover:bg-gray-50 transition-colors duration-300 flex items-center justify-center border bg-white text-lg mt-4 text-gray-500 hover:text-purple-900"
							href="https://www.youtube.com/channel/UCKgozqa8NbuaD6Wck8S04cA"
							target="_blank"
							rel="noreferrer"
						>
							<FontAwesomeIcon icon={faYoutube} />
						</a>
					</li>
					<li className="flex-grow -mr-px">
						<a
							className="w-full h-12 rounded-r hover:bg-gray-50 transition-colors duration-300 flex items-center justify-center border bg-white text-lg mt-4 text-gray-500 hover:text-purple-900"
							href="https://blog.masterchallenge.me/"
							target="_blank"
							rel="noreferrer"
						>
							<FontAwesomeIcon icon={faMediumM} />
						</a>
					</li>
				</ul>
			</div>
		</div>

		<div className="container mx-auto p-4">
			<div className="flex flex-wrap items-start">
				<div className="w-1/2 lg:w-1/4">
					<Link href="/">
						<a className="mr-auto block" style={{ maxWidth: '150px' }}>
							<img
								className="block"
								src="/static/img/logo_masterchallenge.svg"
								alt="Logo Masterchallenge"
							/>
						</a>
					</Link>
				</div>
				<div className="w-1/2 lg:w-1/4">
					<ul className="font-sans leading-loose text-sm">
						<li>
							<Link href="/challenge-spaces">
								<a className="hover:text-blue-600">Challenge spaces</a>
							</Link>
						</li>
						<li>
							<Link href="/for-educational-institutions">
								<a className="hover:text-blue-600">
									For educational institutions
								</a>
							</Link>
						</li>
						<li>
							<Link href="/for-educators">
								<a className="hover:text-blue-600">For educators</a>
							</Link>
						</li>
						<li>
							<Link href="/for-companies">
								<a className="hover:text-blue-600">For companies</a>
							</Link>
						</li>
						<li>
							<Link href="/for-students">
								<a className="hover:text-blue-600">For students</a>
							</Link>
						</li>
						<li>
							<Link href="/login">
								<a className="hover:text-blue-600">Login</a>
							</Link>
						</li>
					</ul>
				</div>
				<div className="w-1/2 lg:w-1/4">
					<ul className="font-sans leading-loose text-sm">
						<li>
							<Link href="/about-us">
								<a className="hover:text-blue-600">About us</a>
							</Link>
						</li>
						<li>
							<a
								href="https://blog.masterchallenge.me/"
								target="_blank"
								className="hover:text-blue-600"
								rel="noreferrer"
							>
								Blog
							</a>
						</li>
						<li>
							<Link href="/join-us">
								<a className="hover:text-blue-600">Jobs</a>
							</Link>
						</li>
						<li>
							<Link href="/contact">
								<a className="hover:text-blue-600">Contact</a>
							</Link>
						</li>
						<li>
							<a
								className="hover:text-blue-600"
								target="_blank"
								href="/static/files/privacy_policy.pdf"
							>
								Privacy policy
							</a>
						</li>
						<li>
							<a
								className="hover:text-blue-600"
								target="_blank"
								href="/static/files/terms_and_conditions.pdf"
							>
								Terms &amp; conditions
							</a>
						</li>
					</ul>
				</div>
				<div className="w-1/2 lg:w-1/4 lg:my-0 md:my-4">
					<p className="font-sans leading-loose text-sm mb-2">
						Nieuwmarkt 9 D
						<br />
						1011 JR Amsterdam
						<br />
						<a href="tel:+31622090699">+316 220 906 99</a>
					</p>
					<p className="font-sans leading-loose text-sm">KvK: 81630514</p>
					<p className="font-sans leading-loose text-sm">BTW: NL862162956B01</p>
					<p className="font-sans leading-loose text-sm">
						Design &amp; code by{' '}
						<a
							target="_blank"
							className="text-blue-600"
							href="https://bootmine.com"
							rel="noreferrer"
						>
							Bootmine
						</a>
					</p>
				</div>
			</div>
		</div>
		<CookieConsent
			location="bottom"
			buttonText="OK, continue"
			cookieName="cookieconsent_status"
			cookieValue="allow"
			declineButtonText="Deny"
			style={{ background: '#44337a' }}
			enableDeclineButton={true}
			buttonClasses="cc-allow"
			buttonStyle={{
				color: '#4e503b',
				borderRadius: '1.5rem',
				padding: '0.75rem 1.25rem',
				backgroundColor: '#ffffff',
				fontSize: '16px',
			}}
			declineButtonStyle={{ backgroundColor: 'transparent', color: '#fff' }}
			expires={150}
		>
			Master Challenge uses cookies to measure performance and improve your
			experience.{' '}
			<a href="/static/files/privacy_policy.pdf" className="underline">
				More information
			</a>
		</CookieConsent>
	</footer>
)

export default Footer
